import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';

const StyledButtons = styled.div.withConfig({
  componentId: "sg1730__sc-1uhl55a-0"
})(["align-items:center;display:flex;flex-direction:column;margin-bottom:", ";flex-wrap:", ";", " ", "{flex-direction:row;}&{column-gap:", ";row-gap:", ";}"], props => !props.$noMargin && tokens.space.lg, props => props.$wrap, props => {
  if (props.$align === 'center') {
    return css(["justify-content:center;"]);
  } else if (props.$align === 'right') {
    return css(["justify-content:center;", "{justify-content:flex-end;}"], media.sm);
  } else {
    return;
  }
}, media.sm, tokens.space.md, tokens.space.md);

export { StyledButtons };
